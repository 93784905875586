import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonInput from 'components/ArgonInput';
import IllustrationLayout from 'layouts/authentication/components/IllustrationLayout'
import React, { useState } from 'react'
import { ConnectButton } from '360dialog-connect-button';
import { Alert, Snackbar, styled } from '@mui/material';
import ArgonTypography from 'components/ArgonTypography';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/polo.png'


const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState({});
    const navigate = useNavigate()

    const StyledConnectButton = styled(ConnectButton)`
        outline: none;
        background: #1f2937;
        color: white;
        padding: 15px 16px;
        width: 100%;
        font-family: Open Sans;
        border-radius: 10px;
        font-weight: bold;
        font-size: 0.875rem;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
        margin-top: 32px;
        border: none;
        &:hover {
            cursor: pointer;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            transform: translateY(-1px);
        }
    `;

    const bgImage = "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

    const handleConnectButton = () => {
        console.log('connect button clicked');
    }

    const handleLogin = async () => {
        setLoading(true);

        try {
            if (!email) {
                setErrors({ email: 'O e-mail é obrigatório' });
                return;
            }

            if (!password) {
                setErrors({ password: 'A senha é obrigatória' });
                return;
            }

            const response = await axios.post("/api/token", {
                username: email,
                password: password
            })

            if (response.status == 200) {
                const token = response.data.access_token
                localStorage.setItem('token360', token)
                navigate('/dashboard')
            } else {
                setAlert({ message: 'Não foi possível realizar login', type: 'error' });
            }
        } catch (error) {
            setAlert({ message: 'Usuário ou senha inválidas', type: 'error' });
        } finally {
            setLoading(false);
        }
    }

    return (
        <IllustrationLayout
            title="Fazer Login"
            description="Entre com seu email e senha para fazer login."
            illustration={{
                image: bgImage,
                title: '"A tecnologia move o mundo."',
                description:
                    "Steve Jobs",
            }}
        >
            {alert.message &&
                <Snackbar open={alert.message.length > 0} autoHideDuration={6000} onClose={() => setAlert({})}>
                    <Alert variant="filled" severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            }
            
            <ArgonBox mb={4} display="flex" justifyContent="center">
                <img src={logo} alt="Polo" width="400px" />
            </ArgonBox>

            <ArgonBox component="form" role="form">


                <ArgonBox mb={2}>
                    <ArgonInput type="email" placeholder="Email" size="large" onChange={(e) => setEmail(e.target.value)} />
                    {errors.email &&
                        <ArgonTypography color="error" fontSize="0.775rem" mt={0.5} mb={2}>
                            {errors.email}
                        </ArgonTypography>
                    }
                </ArgonBox>
                <ArgonBox mb={2}>
                    <ArgonInput type="password" placeholder="Password" size="large" onChange={(e) => setPassword(e.target.value)} />
                    {errors.password &&
                        <ArgonTypography color="error" fontSize="0.775rem" mt={0.5} mb={2}>
                            {errors.password}
                        </ArgonTypography>
                    }
                </ArgonBox>
                <ArgonBox mt={4} mb={0}>
                    <ArgonButton color="info" size="large" fullWidth onClick={handleLogin} disabled={loading}>
                        {loading ? 'Carregando...' : 'Entrar'}
                    </ArgonButton>
                </ArgonBox>
            </ArgonBox>

            <ArgonBox mb={2} mt={0}>
                <StyledConnectButton
                    partnerId={'vcQttGPA'}
                    callback={handleConnectButton}
                    label={'Criar conta Whatsapp Business 360'}
                />
            </ArgonBox>
        </IllustrationLayout>
    )
}

export default Login