import { Loop } from '@mui/icons-material'
import { Card, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'
import ArgonBox from 'components/ArgonBox'
import ArgonTypography from 'components/ArgonTypography'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from "uuid";

const InfoValue = ({ title, value, loading }) => {
    return (
        <>
            <ArgonBox
                component="td"
                p={1}
                align="left"
                lineHeight={0.65}
            >
                <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ display: "inline-block", width: "max-content", textTransform: "uppercase" }}
                >
                    {title}
                </ArgonTypography>
            </ArgonBox>
            <ArgonBox
                component="td"
                p={1}
                align="left"
                lineHeight={0.65}
            >
                <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ display: "inline-block", width: "max-content" }}
                >
                    {loading ? <CircularProgress color={'dark'} size={20} /> : value}
                </ArgonTypography>
            </ArgonBox>
        </>
    )
}

const Informacoes = () => {
    const navigate = useNavigate()
    const [editUrl, setEditUrl] = useState(false)
    const [loading, setLoading] = useState(false)
    const [informacoes, setInformacoes] = useState({})

    const getInformacoes = async () => {
        try {
            setLoading(true)
            const response = await axios.get("/api/partners/vcQttGPA", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token360')}`
                }
            })

            setInformacoes(response.data)
        } catch (e) {
            if (e.response.status === 401) {
                navigate('/login')
                return
            }
            alert('Não foi possível carregar as informações')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const token360 = localStorage.getItem('token360')
        if (!token360) {
            navigate('/login')
            return
        }

        getInformacoes()
    }, [navigate])

    return (
        <DashboardLayout>
            <DashboardNavbar breadcrumbName="Informações" />
            <ArgonBox py={3}>
                <ArgonBox mb={3}>
                    <Card>
                        <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <ArgonTypography variant="h6">Informações do Parceiro</ArgonTypography>
                        </ArgonBox>
                        <ArgonBox
                            px={3}
                            py={2}
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                }
                            }}
                        >
                            <Table>
                                <TableRow>
                                    <ArgonBox component="td" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                                        ITEM
                                    </ArgonBox>
                                    <ArgonBox component="td" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                                        VALOR
                                    </ArgonBox>
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Permite que o cliente adicione número de telefone"
                                        value={informacoes?.allow_client_to_add_phone_no === true ? 'Sim' : 'Não'}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Sistema de cobrança utilizado"
                                        value={informacoes?.billing_system?.toUpperCase()}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Bloqueia novas submissões"
                                        value={informacoes?.blocked_new_submission === true ? 'Sim' : 'Não'}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Nome da Marca"
                                        value={informacoes?.brand_name?.toUpperCase()}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="País de operação"
                                        value={informacoes?.country}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Identificador Único"
                                        value={informacoes?.id}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    {loading && (
                                        <InfoValue
                                            title="Logo"
                                            value={'-'}
                                            loading={loading}
                                        />
                                    )}
                                    {!loading && (
                                        <InfoValue
                                            title="Logo"
                                            value={
                                                <ArgonBox>
                                                    <img src={informacoes?.logo_url} alt="Logo" width={"100px"}/>
                                                </ArgonBox>
                                            }
                                            loading={loading}
                                        />
                                    )}
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Permite adicionar parâmetros ao deeplink de onboarding"
                                        value={informacoes?.onboarding_deeplink_add_params === true ? 'Sim' : 'Não'}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="URL de redirecionamento do parceiro"
                                        value={informacoes?.partner_redirect_url}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Moeda do plano de pagamento"
                                        value={informacoes?.payment_plan?.currency}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Preço do plano de pagamento"
                                        value={informacoes?.payment_plan?.price}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="ID do preço no Stripe"
                                        value={informacoes?.payment_plan?.stripe_price_id}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Pagamento obrigatório"
                                        value={informacoes?.payment_required === true ? 'Sim' : 'Não'}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Chave pública"
                                        value={informacoes?.publishable_key}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="ID do gerenciador de negócios para compartilhamento de conta"
                                        value={informacoes?.settings?.account_sharing?.business_manager_id}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="ID da solução para compartilhamento de conta"
                                        value={informacoes?.settings?.account_sharing?.solution_id}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Região padrão para localização de dados"
                                        value={informacoes?.settings?.default_data_localization_region}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Aprovação automática para mudança de parceiro"
                                        value={informacoes?.settings?.partner_change_request?.auto_approve === true ? 'Sim' : 'Não'}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="Preços escalonados padrão habilitados"
                                        value={informacoes?.settings?.standard_tiered_pricing_enabled === true ? 'Sim' : 'Não'}
                                        loading={loading}
                                    />
                                </TableRow>

                                <TableRow>
                                    <InfoValue
                                        title="URL do webhook"
                                        value={informacoes?.webhook_url}
                                        loading={loading}
                                    />
                                </TableRow>
                            </Table>
                        </ArgonBox>
                    </Card>
                </ArgonBox>
            </ArgonBox>
            <Footer />
        </DashboardLayout>
    )
}

export default Informacoes