import { Box, Card, Icon, IconButton, InputLabel, TextField } from '@mui/material'
import axios from 'axios'
import ArgonBox from 'components/ArgonBox'
import ArgonInput from 'components/ArgonInput'
import ArgonTypography from 'components/ArgonTypography'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const DashboardPolo = () => {
  const navigate = useNavigate()
  const [editUrl, setEditUrl] = useState(false)
  const [webhookUrl, setWebhookUrl] = useState(false)

  const [valueEditUrl, setValueEditUrl] = useState('')
  const [valueWebhookUrl, setValueWebhookUrl] = useState('')

  const getInformacoes = async () => {
    try {
      const response = await axios.get("/api/partners/vcQttGPA", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token360')}`
        }
      })
      setValueEditUrl(response.data.partner_redirect_url)
      setValueWebhookUrl(response.data.webhook_url)
    } catch (e) {
      if (e.response.status === 401) {
        navigate('/login')
        return
      }
      alert('Não foi possível carregar as informações')
    }
  }

  const saveRedirectUrl = () => {
    try {
      axios.patch("/api/partners/vcQttGPA", {
        partner_redirect_url: valueEditUrl
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token360')}`
        }
      })

      setEditUrl(false)
      alert('URL de redirecionamento salva com sucesso')
    } catch (e) {
      alert('Não foi possível salvar a URL de redirecionamento')
    }
  }

  const saveWebhookUrl = () => {
    try {
      axios.patch("/api/partners/vcQttGPA", {
        webhook_url: valueWebhookUrl
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token360')}`
        }
      })
    } catch (e) {
      alert('Não foi possível salvar a URL de webhook')
    }
  }

  useEffect(() => {
    const token360 = localStorage.getItem('token360')
    if (!token360) {
      navigate('/login')
      return
    }

    getInformacoes()
  }, [navigate])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">Configurações Obrigatórias</ArgonTypography>
            </ArgonBox>
            <ArgonBox
              px={3}
              py={2}
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <InputLabel shrink={true}>URL de Redirecionamento</InputLabel>
              <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                <ArgonInput
                  disabled={!editUrl}
                  placeholder="URL de Redirecionamento"
                  onChange={(e) => setValueEditUrl(e.target.value)}
                  value={valueEditUrl}
                  startAdornment={
                    <Icon fontSize="small" style={{ marginRight: "6px" }}>
                      link
                    </Icon>
                  }
                />
                <Box sx={{ display: !editUrl ? 'flex' : 'none' }}>
                  <IconButton type="button" sx={{ p: '10px' }} onClick={() => setEditUrl(!editUrl)}>
                    <Icon fontSize="small" color="primary">edit</Icon>
                  </IconButton>
                </Box>
                <Box sx={{ display: editUrl ? 'flex' : 'none' }}>
                  <IconButton type="button" sx={{ p: '10px' }} onClick={() => saveRedirectUrl() }>
                    <Icon fontSize="small" color="success">save</Icon>
                  </IconButton>
                </Box>
                <Box sx={{ display: editUrl ? 'flex' : 'none' }}>
                  <IconButton type="button" sx={{ p: '10px' }} onClick={() => setEditUrl(!editUrl)}>
                    <Icon fontSize="small" color="error">close</Icon>
                  </IconButton>
                </Box>

              </Box>
            </ArgonBox>

            <ArgonBox
              px={3}
              py={2}
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <InputLabel shrink={true}>URL de Webhook</InputLabel>
              <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                <ArgonInput
                  disabled={!webhookUrl}
                  placeholder="URL de Webhook"
                  onChange={(e) => setValueWebhookUrl(e.target.value)}
                  value={valueWebhookUrl}
                  startAdornment={
                    <Icon fontSize="small" style={{ marginRight: "6px" }}>
                      link
                    </Icon>
                  }
                />
                <Box sx={{ display: !webhookUrl ? 'flex' : 'none' }}>
                  <IconButton type="button" sx={{ p: '10px' }} onClick={() => setWebhookUrl(!webhookUrl)}>
                    <Icon fontSize="small" color="primary">edit</Icon>
                  </IconButton>
                </Box>
                <Box sx={{ display: webhookUrl ? 'flex' : 'none' }}>
                  <IconButton type="button" sx={{ p: '10px' }} onClick={() => saveWebhookUrl() }>
                    <Icon fontSize="small" color="success">save</Icon>
                  </IconButton>
                </Box>
                <Box sx={{ display: webhookUrl ? 'flex' : 'none' }}>
                  <IconButton type="button" sx={{ p: '10px' }} onClick={() => setWebhookUrl(!webhookUrl)}>
                    <Icon fontSize="small" color="error">close</Icon>
                  </IconButton>
                </Box>

              </Box>
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  )
}

export default DashboardPolo