import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const CheckLogin = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const token360 = localStorage.getItem('token360')
        if (!token360) {
            navigate('/login')
            return
        }

        navigate('/dashboard')
    }, [navigate])


    return (
        <></>
    )
}

export default CheckLogin